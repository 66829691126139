// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._16Ig28bzhHfiPnUeRiRfim {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n._2zXdRCdXnKSUTIP2M9wPxI {\n    table-layout: fixed;\n}\n\n._3j7hRlI3c4KKJ3mcIN0-1m.alert {\n    margin-bottom: 10px;\n}\n\n._3j7hRlI3c4KKJ3mcIN0-1m h4 {\n    font-size: 1.44rem; /* theme.fonts.size.body h4 */\n    margin-bottom: 0.5em;\n}\n\n._3j7hRlI3c4KKJ3mcIN0-1m ul {\n    list-style: disc;\n    margin: 0.5em 0;\n}\n", "",{"version":3,"sources":["webpack://./../graylog2-server/graylog2-web-interface/src/components/event-definitions/common/commonStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB,EAAE,6BAA6B;IACjD,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[":local(.title) {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n:local(.fixedTable) {\n    table-layout: fixed;\n}\n\n:local(.validationSummary).alert {\n    margin-bottom: 10px;\n}\n\n:local(.validationSummary) h4 {\n    font-size: 1.44rem; /* theme.fonts.size.body h4 */\n    margin-bottom: 0.5em;\n}\n\n:local(.validationSummary) ul {\n    list-style: disc;\n    margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_16Ig28bzhHfiPnUeRiRfim",
	"fixedTable": "_2zXdRCdXnKSUTIP2M9wPxI",
	"validationSummary": "_3j7hRlI3c4KKJ3mcIN0-1m"
};
export default ___CSS_LOADER_EXPORT___;
